import '@blixem/cms-bundle/admin/vendor/fontawesome-pro/css/all.min.css';
import './style/theme.less';
import './cookies.js';

document.addEventListener('DOMContentLoaded', function() {
    const menuButton = document.querySelector('.eliverhage-header-mobile-menu-button');
    const header = document.querySelector('.eliverhage-header');
    const main = document.querySelector('.eliverhage-main');
    
    menuButton.addEventListener('click', e => {
        e.preventDefault();
        header.classList.toggle('eliverhage-mobile-menu-open');
    });

    if (main && main.firstElementChild && main.firstElementChild.classList.contains('eliverhage-section-videoheader')) {
        header.classList.add('eliverhage-header-blend');
    }

    const vimeos = document.querySelectorAll('.eliverhage-portfolio-item-image, .eliverhage-portfolio-full-item-image');
    vimeos.forEach(vimeo => {
        vimeo.addEventListener('click', e => {
            e.preventDefault();
            vimeos.forEach(v => {
                const iframe = v.querySelector('iframe');
                if(iframe){
                    const player = new Vimeo.Player(iframe);
                    if(v !== vimeo){
                        player.pause();
                        v.classList.remove('playing');
                    } else {
                        v.classList.toggle('playing');
                        if(v.classList.contains('playing')){
                            player.play();
                        } else {
                            player.pause();
                        }
                    }
                } else {
                    v.classList.remove('playing');
                }
            });
        });
    });

    window.addEventListener('scroll', () => {
        vimeos.forEach(vimeo => {
            const iframe = vimeo.querySelector('iframe');
            if(iframe){
                const player = new Vimeo.Player(iframe);
                const rect = vimeo.getBoundingClientRect();
                if(rect.top > window.innerHeight || rect.bottom < 0){
                    player.pause();
                    vimeo.classList.remove('playing');
                }
            }
        });
    });

});